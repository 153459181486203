//$base-path: '../';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "hamburgers/hamburgers.scss";
@import "_config.scss";

$main: #000000;
$bg-light: #e5f7fc;
$bg: #cfedf5;
$bg-superlight: #f2fdff;
$facebook: #3969cf;
$twitter: #00a6e7;


@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, -10px); }
    to   { transform: translate(0, -0px); }    
}

html{
  height: 100%;
  box-sizing: border-box;
  margin:0;
  padding:0;
  overflow-x:hidden; 
  width: 100%;
}

body {
  overflow-x:hidden; 
  margin:0;
  padding:0;
  width: 100%;
  color:$main;
  position: relative;
  margin: 0;
  padding-bottom: 0;
  min-height: 100%;
  font-family: 'Lato', sans-serif;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.header {
  height:100px;
  background: rgba(255,255,255,0.7);
  position:relative;
  z-index: 1;
  @media screen and (max-width : 900px) {
    z-index: 102;
  }

  .container {
    position:relative;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    max-width: 750px !important;
  }

  .mobile-logo {
    position:absolute;
    top:5px;
    left: 5px;
    height: 60px;
    width: 60px;
    z-index: 1000;
    display: none;
    background-position:center;
    background-image:url(../images/mdis/logo-big.png);
    background-size: contain;
    background-repeat:no-repeat;

    @media screen and (max-width : 991px) {
    }
    @media screen and (max-width : 767px) {
      display: block;
    }
  }

  .main-logo {
    position:absolute;
    top:-35px;
    left: 0px;
    height: 90px;
    width: 60px;
    background-position:center;
    background-image:url(../images/mdis/logo-big.png);
    background-size: contain;
    background-repeat:no-repeat;

    @media screen and (max-width : 991px) {
    }
    @media screen and (max-width : 767px) {
    }
  }

  @media screen and (max-width : 991px) {
    height: 90px;
  }
  @media screen and (max-width : 767px) {
    height:90px;
  }

  .mob-btn {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
    display: none;

    @media screen and (max-width : 767px) {
      display:block;
    }

    button {
      outline: 0 !important;
      background: #212446;

      @media screen and (max-width : 767px) {
        background: #fff;
      }
    }
  }

  .mob-menu.full {
    height: 100%;
  }

  .mob-menu {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    height: 0;
    opacity: 0;
    transition: opacity .2s ease;
    transition-delay: .2s;
    transform:translate(0%,0);
    z-index: -1;
    overflow: hidden;

    .links {
      display: block;
      position: absolute;
      min-width: 100%;
      top: 50%;
      width: 100%;
      padding-left:0;
      left: 0;
      text-align: center;
      padding-bottom: 80px;
      transform: translate(0, -50%);

      .link {
        transform:scale(1,1);
        transition:transform .2s ease;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 13pt;
        font-weight: 900;
        float: none;
        color: #000000;
      }

      .link:hover, .link.active {
        transform:scale(1.1,1.1);
        color: #203c5b;    
      }

      .link.active {
        text-decoration:underline;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .mob-menu.in {
    opacity:1;
    height: 100%;
    z-index: 100;
    transform:translate(0,0);
  }

  .links {
    z-index: 2;
    position: absolute;
    top: 45px;
    width: 100%;
    left: 0;
    padding-left:140px;
    min-width: 385px;

    @media screen and (max-width : 767px) {
      display:none;
    }

    .link {
      float: left;
      transition: color .3s ease;
      font-weight: 500;
      font-size: 11pt;
      color:#000000;
      opacity:0.9;
      padding-left: 26px;
      padding-right: 26px;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 900;

    }

    .link.twolines {
      margin-top: -9px;
    }

    .link.active {
      opacity:1;
      text-decoration:underline;
    }

    .link:hover {
      opacity:1;
      text-decoration:underline;
    }
  }

  a {
    text-decoration: none;
  }

}

.stripe-red {
  margin-top: 20px;
  position:absolute;
  height: 150px;
  width: 100%;
  background-color:#ffffff;

   @media screen and (max-width : 767px) {
    height: 100px;
    margin-top: 0;
   }

  .stripe-background {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    background-position:center;
    background-size:cover;
  }

  .stripe-foreground {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
    background-image:url(../images/mdis/home-title.png);

    @media screen and (max-width : 767px) {
      left: 10px;
      right: 10px;
      margin-left: auto;
      margin-right: auto;
      background-size:contain;
      background-position:0 50%;
    }

  }
}

.app-icon {
  height: 80px;
  width: 80px;
  background-repeat:no-repeat;
  background-position:center;
  background-size:contain;
  background-image:url(../images/app-icon.png);
}

.header-fixed {
  section#cover {
    margin-top: 50px;
  }
}

section {
  outline: none;
}

.container {
  position:relative;
  margin-right: auto;
  margin-left: auto;
  width: 100% !important;
  max-width: 750px !important;
}

section#cover.in-view.loaded-view {
  .phone-hand {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  .stripe-foreground {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  .cover-image-container {
    .cover-image {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }
} 

section#cover {
  margin-top: 0px;
  position:relative;
  padding-top:140px;
  padding-bottom:20px;

  @media screen and (max-width : 767px) {
    padding-top: 110px;
  }

  .stripe-red {
    top: 0;
  }

  .stripe-foreground {
    transition:all .5s ease;
    opacity: 0;
    transform: translate(0px, -30px);
  }

  .arrows-down {
    position:absolute;
    right: 0;
    width: 100%;
    bottom:10px;
    height: 30px;
    z-index: 10;
    opacity: 0.8;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:contain;
    background-image:url(../images/icons/arrows-down.png);
    animation-name: floating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

  }

  .claim-container {
    z-index: 100;
    padding-left:0;

    .row {
      z-index: 1000;
      position:relative;
      margin-left: 0;
      margin-right: 0;
      padding-left:160px;
      @media screen and (max-width : 767px) {
        padding-left:20px;
      }
    }
  }

  .claim {
    display: inline-block;
    margin-top: -20px;
    margin-bottom: 40px;
    padding-right:50px;

    @media screen and (max-width : 767px) {
      padding-right: 10px;
      padding-left:10px;
    }
    h2 {
      font-weight: 900;
      color: #000000;
      margin-bottom: 30px;
      font-size: 17pt;
      text-transform: uppercase;
      strong {
        color: #f29100;
        font-weight: 900;
      }

      @media screen and (max-width : 767px) {
        font-size: 13pt;
      }
      @media screen and (max-width : 350px) {
        font-size: 12pt;
      }
    }

    p {
      color: #000000;
      line-height: 150%;
      font-weight:300;
      font-size: 12pt;
      strong {
        color: #f29100;
        font-weight: 700;
        font-size: 110%;
      }

      b {
        font-size: 110%;
        font-weight: 700;
      }

      @media screen and (max-width : 767px) {
        font-size: 11pt;
      }
      @media screen and (max-width : 350px) {
        font-size: 10pt;
      }
    }
  }

  .cover-image-container {
    position:relative;
    width: 100%;
    margin-top: -20px;
    padding-bottom:70px;
    @media screen and (max-width : 767px) {
      padding-bottom:70px;
    }
    .cover-background {
      position:absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 200px;
      bottom:0;
      background-color:#fde7c2;
      @media screen and (max-width : 767px) {
        height: 150px;
      }
    }
    .cover-image {
      transition: all 0.8s ease;
      transition-delay: 0.4s;
      opacity: 0;
      transform: translate(0, 5px) scale(1);
      position:relative;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 750px;
      height: 300px;
      background-repeat:no-repeat;
      background-position:50% 50%;
      background-size:contain;
      background-image:url(../images/mdis/home-cover.png);
      @media screen and (max-width : 767px) {
        height: 200px;
      }
    }
  }
}

section#newsletter {
  .newsletter-container {
    width: 100%;
    max-width: 800px;
    padding:10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 80px;
    .claim {

      h3 {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 22pt;
        line-height: 130%;
        font-weight: 800;
        color: #575656;
        strong {
          color:#76121d;
        }
        @media screen and (max-width : 767px) {
          font-size: 22pt;
        }
        @media screen and (max-width : 350px) {
          font-size: 17pt;
        }
      }

      h5 {
        font-size: 22pt;
        font-weight: 300;
        color:#575656;
        margin-bottom: 20px;
        @media screen and (max-width : 767px) {
          font-size: 19pt;
        }
        @media screen and (max-width : 350px) {
          font-size: 13pt;
        }
      }
    }

    .iscriviti-area {
      position:relative;
      margin-bottom: 80px;
      @media screen and (max-width : 767px) {
        margin-bottom: 40px;
      }
      .iscriviti-image {
        display: inline-block;
        height: 80px;
        width: 80px;
        background-repeat:no-repeat;
        background-position:50% 50%;
        background-size:contain;
        background-image:url(../images/icons/email.png);
      }

      .iscriviti-button {
        position:absolute;
        left: 100px;
        transition: opacity .2s ease;
        top: 50%;
        transform: translate(0, -50%);
        border:none;
        color: #fff;
        padding:20px;
        padding-top:4px;
        padding-bottom:4px;
        font-weight: 300;
        background-color:#c5a5a5;
        border-radius:5px;
        font-size: 17pt;

        @media screen and (max-width : 767px) {
          font-size: 17pt;
        }
      }

      .iscriviti-button:hover, .iscriviti-button:active {
        opacity: 0.8;
      }
     
    }

    .image-pc {
      width: 100%;
      height: 250px;
      margin-top: 100px;
      background-repeat:no-repeat;
      background-position:0% 50%;
      background-size:contain;
      background-image:url(../images/pc-screen.png);
      @media screen and (max-width : 767px) {
        background-position:50% 50%;
        margin-top: 40px;
      }
    }
  }
}

section#servizi.in-view {
  .pluses-container {
    opacity: 1;
    transform: translate(0, 0);
    .plus-container{
      .plus {
        .plus-image {
          transform:scale(1);
        }
        .plus-image-rotate {
          transform: rotate(0);
        }
      }
    }
  }
}

section#servizi {
  background-color:#ffffff;
  .pluses-container {
    
    padding-top:40px;
    padding-bottom:40px;
    text-align: center;

    .pluses-section {
      background-color:#f2f2f2;

      .pluses-section-container {
        width: 100%;
        max-width: 1000px;
        padding:10px;
        margin-right: auto !important;
        margin-left: auto !important;
      }
    }

    .pluses-section.dark {
      background-color:#e6e6e6;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 900;
      font-size: 17pt;
      text-transform: uppercase;
      color: #f39200;

      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }

    h2 {
      margin-bottom: 10px;
      font-weight: 900;
      font-size: 17pt;
      text-transform: uppercase;
      color: #555555;

      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }

    .plus-container {
      display: inline-block;
      width: 24.5%;
      text-align: center;
      overflow: hidden;
      @media screen and (max-width : 767px) {
        width: 49.5%;
      }
      .plus {
        padding:30px;
        padding-top:150px;
        position:relative;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        .plus-image {
          position:absolute;
          width: 100%;
          height: 160px;
          top: 0;
          transform:scale(0.6);
          transition: all .8s ease;
          left: 0;
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;
          
        }
        .plus-image-rotate {
          position:absolute;
          width: 100%;
          height: 140px;
          top: 0;
          left: 0;
          transform:rotate(50deg);
          transition: all .8s ease;
          transform-origin: 50% 50%;
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;
          background-image:url(../images/mdis/home-bottom-outside.png);
          
        }
        .plus-text {
          font-size: 11pt;
          font-weight: 900;
          color: #000;
          text-transform: uppercase;
          @media screen and (max-width : 991px) {
            font-size: 9pt;
          }
        }
      }

      .plus.plus-1 {
        .plus-image {
          background-image:url(../images/mdis/home-step-1.png);
        }
      }

      .plus.plus-2 {
        .plus-image {
          background-image:url(../images/mdis/home-step-2.png);
        }
      }

      .plus.plus-3 {
        .plus-image {
          background-image:url(../images/mdis/home-step-3.png);
        }
      }

      .plus.plus-4 {
        .plus-image {
          background-image:url(../images/mdis/home-step-4.png);
        }
      }

      .plus.plus-5 {
        .plus-image {
          background-image:url(../images/mdis/home-step-5.png);
        }
      }

      .plus.plus-6 {
        .plus-image {
          background-image:url(../images/mdis/home-step-6.png);
        }
      }

      .plus.plus-7 {
        .plus-image {
          background-image:url(../images/mdis/home-step-7.png);
        }
      }

      .plus.plus-8 {
        .plus-image {
          background-image:url(../images/mdis/home-step-8.png);
        }
      }

      .plus.plus-9 {
        .plus-image {
          background-image:url(../images/mdis/home-step-1.png);
        }
      }

      .plus.plus-10 {
        .plus-image {
          background-image:url(../images/mdis/home-step-2.png);
        }
      }

      .plus.plus-11 {
        .plus-image {
          background-image:url(../images/mdis/home-step-3.png);
        }
      }

      .plus.plus-12 {
        .plus-image {
          background-image:url(../images/mdis/home-step-4.png);
        }
      }
    }
  }
}

section#primaedicola.in-view {
  .pluses-container {
    opacity: 1;
    transform: translate(0, 0);
    .plus-container{
      .plus {
        .plus-image {
          transform:scale(1);
        }
        .plus-image-rotate {
          transform: rotate(0);
        }
      }
    }
  }
}

section#primaedicola {
  background-color:#dce7f2;
  .pluses-container {
    transform: translate(0, 20px);
    opacity: 0;
    transition: all .8s ease;
    width: 100%;
    max-width: 1000px;
    padding:10px;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-top:40px;
    padding-bottom:40px;
    text-align: center;

    h1 {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 900;
      font-size: 17pt;
      text-transform: uppercase;
      color: #f39200;

      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }

    .plus-container {
      display: inline-block;
      width: 24.5%;
      text-align: center;
      overflow: hidden;
      @media screen and (max-width : 767px) {
        width: 49.5%;
      }
      .plus {
        padding:30px;
        padding-top:150px;
        position:relative;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        .plus-image {
          position:absolute;
          width: 100%;
          height: 140px;
          top: 0;
          transform:scale(0.6);
          transition: all .8s ease;
          left: 0;
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;
          
        }
        .plus-image-rotate {
          position:absolute;
          width: 100%;
          height: 140px;
          top: 0;
          left: 0;
          transform:rotate(50deg);
          transition: all .8s ease;
          transform-origin: 50% 50%;
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;
          background-image:url(../images/mdis/home-bottom-outside.png);
          
        }
        .plus-text {
          font-size: 11pt;
          font-weight: 900;
          color: #000;
          text-transform: uppercase;
        }
      }

      .plus.plus-1 {
        .plus-image {
          background-image:url(../images/mdis/home-bottom-1.png);
        }
        .plus-image-rotate {
          transition-delay: 0.2s;
          transition: all 1s ease;
        }
      }

      .plus.plus-2 {
        .plus-image {
          background-image:url(../images/mdis/home-bottom-2.png);
        }
        .plus-image-rotate {
          transition-delay: 0.4s;
          transition: all .8s ease;
        }
      }

      .plus.plus-3 {
        .plus-image {
          background-image:url(../images/mdis/home-bottom-3.png);
        }
        .plus-image-rotate {
          transition-delay: .6s;
          transition: all .6s ease;
        }
      }

      .plus.plus-4 {
        .plus-image {
          background-image:url(../images/mdis/home-bottom-4.png);
        }
        .plus-image-rotate {
          transition-delay: .8s;
          transition: all .4s ease;
        }
      }
    }
  }
}

section#clienti {
  background-color:#f2f2f2;
  .clienti-container {
    opacity: 1;
    width: 100%;
    max-width: 1300px;
    padding:10px;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-top:40px;
    padding-bottom:40px;
    text-align: center;

    h1 {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 900;
      font-size: 17pt;
      text-transform: uppercase;
      color: #f39200;

      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }

    .plus-container {
      display: inline-block;
      width: 14%;
      text-align: center;
      overflow: hidden;
      @media screen and (max-width : 767px) {
        width: 49.5%;
      }
      .plus {
        padding:0px;
        padding-top:150px;
        position:relative;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        .plus-image {
          position:absolute;
          width: 100%;
          height: 180px;
          top: 0;
          transform:scale(0.6);
          transition: all .8s ease;
          left: 0;
          background-position:center;
          background-size:contain;
          background-repeat:no-repeat;
          
        }
       
        .plus-text {
          font-size: 11pt;
          font-weight: 900;
          color: #000;
          text-transform: uppercase;
        }

        .hidden {
          display: none;
        }
      }

      .plus.plus-1 {
        .plus-image {
          background-image:url(../images/mdis/amazon.png);
        }
      }

      .plus.plus-2 {
        .plus-image {
          background-image:url(../images/mdis/ibs.png);
        }
      }

      .plus.plus-3 {
        .plus-image {
          background-image:url(../images/mdis/nespresso.png);
        }
      }

      .plus.plus-4 {
        .plus-image {
          background-image:url(../images/mdis/panini.png);
        }
      }

      .plus.plus-5 {
        .plus-image {
          background-image:url(../images/mdis/lafeltrinelli.png);
        }
      }

      .plus.plus-6 {
        .plus-image {
          background-image:url(../images/mdis/libraccio.png);
        }
      }
      .plus.plus-7 {
        .plus-image {
          background-image:url(../images/mdis/ferrero.png);
        }
      }
    }
  }
}

section#vaialritiro {
  background-color:#fde7c2;
  .vaialritiro-container {
    width: 100%;
    max-width: 1000px;
    position:relative;
    padding:10px;
    margin-right: auto !important;
    margin-left: auto !important;
    overflow: hidden;
    h2 {
      margin-bottom: 10px;
      font-weight: 900;
      font-size: 17pt;
      text-transform: uppercase;
      color: #555555;

      @media screen and (max-width : 767px) {
        font-size: 15pt;
      }
    }
    p {
      color: #555;
      font-weight: bold;
    }
    button.go-button {
      background-color:#f79121;
      color: #fff;
      border:0px solid transparent;
      padding-top:6px;
      padding-bottom:6px;
      padding-left:20px;
      margin-top: 20px;
      padding-right:20px;
      border-radius:40px;
      font-size: 11pt;
      font-weight: bold;

      @media screen and (max-width : 767px) {
        font-size: 13pt;
      }

    }
    .vaialritiro-image {
      position:absolute;
      left: 0;
      top:15%;
      right: 50%;
      height: 70%;
      width: 50%;
      display: inline-block;
      background-image:url(../images/mdis/home-foot.png);
      background-position:center;
      background-size:contain;
      background-repeat:no-repeat;

      @media screen and (max-width : 767px) {
        width: 100%;
        position:relative;
        top: 0;
        height: 200px;
      }
    }

    .vaialritiro-text {
      position:relative;
      left: 50%;
      display: inline-block;
      width: 50%;
      padding-top:100px;
      padding-bottom:100px;

      @media screen and (max-width : 767px) {
        width: 100%;
        position:relative;
        left: 0;
        padding:10px;
        text-align:center;
      }
    }
  }
}

section#ritiro.in-view {
  .text-container {
    opacity: 1;
    transform: translate(0, 0);
  }
}

section#ritiro {
  padding-top:0px;
  padding-bottom:20px;
  text-align:center;
  color: #000;

  .text-container {
    transform: translate(0, 20px);
    opacity: 0;
    transition: all .8s ease;
    max-width: 550px;
    width: 100%;
    padding:10px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-weight: 900;
    font-size: 17pt;
    text-transform: uppercase;
    color: #f39200;

    @media screen and (max-width : 767px) {
      font-size: 15pt;
    }
  }

  h3 {
    line-height: 150%;
    font-weight: 900;
    font-size: 14pt;
    strong {
      color: #f39200;
    }

    @media screen and (max-width : 767px) {
      font-size: 12pt;
    }
  }
}

section#footer {
  padding-top:80px;
  padding-bottom:40px;
  .footer-logo {
    position:absolute;
    left: 0px;
    top: 30px;
    height: 80px;
    width: 80px;
    background-image:url(../images/mdis/logo-big.png);
    background-size: contain;
    background-position:center;
    background-repeat:no-repeat;

    @media screen and (max-width : 767px) {
      top: 0;
      left: 50%;
      margin-left: -40px;
    }
  }

  .footer-container {
    padding-left:150px;
    position:relative;
    background-color:#ffffff;
    padding-top:20px;
    padding-bottom:20px;
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    text-align: left;

    @media screen and (max-width : 767px) {
      padding-left:0;
      padding-top:120px;
    }

    .footer-title {
      font-weight: 900;
    }

    a {
      color: #000;
    }

    .link {
      padding-top:3px;
      padding-bottom:3px;
      color: #000;
      font-size: 10pt;
    }

    .footer-block {
      display: inline-block;
      width: 32%;

      @media screen and (max-width : 767px) {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
      }
    }
    .footer-logo {
      img {
        height: 60px;
      }
    }
  }
}

section#colophon{
  text-align: center;
  overflow: hidden;
  background-color:#fde7c2;
  padding-left:10px;
  padding-right:10px;
  .colophon-container {
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 11pt;
    color: #555;
    padding-left:10px;
  }
}

div.popup.in {
  opacity: 1;
  transform: translate(0px, 0%) rotate(90deg);
  @media screen and (max-width : 767px) {
    transform: translate(50%, 0%) rotate(0deg);
  }
}
div.popup {
  z-index: 10002;
  transition: all .6s ease;
  opacity: 0;
  transform: translate(30px, 0%) rotate(90deg);
  transform-origin: 100% 0%;
  position: fixed;
  top:50%;
  right: 0px;
  text-align: center;
  background-color:#f39200;
  border-radius:0px;
  
  @media screen and (max-width : 767px) {
    top: 10px;
    transform: translate(50%, 0%) rotate(0deg);
    right: 50%;
  }

  h4 {
    letter-spacing: 4px;
  }

  a {
    padding:5px;
    padding-left:30px;
    padding-right:30px;
    width: 100%;
    height: 100%;
    color: #000000;
    font-weight: 900;
    display: inline-block;
    text-decoration: none !important;
  }

}

section#policy{
  padding:10px;
  .text-container {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

section#form {
  width: 100%;
  overflow: hidden;
  padding:10px;
  padding-bottom:150px;
  .form-container {
    position: relative;
    width: 100%;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    .form-group {
      display: block;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }

  .btn-primary {
    background-color:#e9983f;
    border:none;
    border-radius:30px;
    margin-top: 10px;
    padding-left:20px;
    padding-right:20px;
  }
}

#punti-di-ritiro {
  .punti-container {
    position:relative;
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .claim {
      max-width: 100%;
      text-align: center;
      padding-left:10px;
      padding-right:10px;
      margin:auto;
      margin-bottom: 20px;

      h1 {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 900;
        font-size: 20pt;
        text-transform: uppercase;
        color: #f39200;

        @media screen and (max-width : 767px) {
          font-size: 17pt;
        }
      }

      h3 {
        text-align: left;
        width: 100%;
        font-weight: bold;
        font-size: 14pt;
        line-height: 150%;

        @media screen and (max-width : 767px) {
          font-size: 11pt;
        }

        strong {
          color: #f39200;
        }
      }
    }

    .map-search {
      position:relative;
      overflow: hidden;
    }

    .search-container {
      width: 49%;
      display: inline-block;
      float: left;
      height: 0;
      padding-bottom: 49%;
      position:relative;

      @media screen and (max-width : 767px) {
        width: 100%;
        position:relative;
        top: 310px;
        height: 800px;
      }


      .input-container {
        position:absolute;
        top: 0;
        width: 100%;
        padding-left:10px;
        padding-right: 10px;
        position:relative;

        .search-input {
          height: 40px;
          border:1px solid #000;
          padding-left:10px;
          width: 100%;
          padding-right:50px;
          box-shadow:none;
          background:#fff;
          color: #333;
        }

        .search-icon {
          width: 40px;
          height: 40px;
          background-image: url(../images/icons/search.png);
          background-repeat: no-repeat;
          background-position:center;
          background-size: 30px 30px;
          position:absolute;
          right: 15px;
          top: 0;
        }
      }

      .loading-results {
        position:absolute;
        top: 40px;
        bottom: 0;
        position:absolute;
        left: 0;
        right: 0;
        background-color:rgba(255,255,255,0.5);
        z-index: 10;

        .spinner {
          position:absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: url(../images/loader.gif);
          background-size:contain;
          background-repeat:no-repeat;
          background-position:center;
          width: 200px;
          height: 200px;

          @media screen and (max-width : 767px) {
            top: 10%;
          }
        }
      }

      .no-results {
        margin-top: 20px;
      }

      .search-results {
        z-index: 9;
        position:absolute;
        top: 40px;
        bottom: 0; 
        padding-left:20px;
        padding-right: 10px;
        margin-right: 10px;
        overflow-y:scroll;
        position:absolute;
        -webkit-overflow-scrolling: touch;
        left: 0;
        right: 0;

        @media screen and (max-width : 767px) {
          overflow: auto;
          bottom:unset;
          margin-right: 0px;
          top:40px;
          height: 400px;
          padding-left:15px;
          padding-right: 15px;
        }
        .result-item:hover, .result-item:focus, .result-item.active {
          color: #f39200;

          .distance {
            transform: scale(1.4);
          }

          .go-to{
            opacity: 1;
          }
        }

        .result-item {
          width: 100%;
          height: 80px;
          overflow: hidden;
          position:relative;
          font-weight: bold;
          transition: all .2s ease;
          .area {
            width: 100%;
            position:absolute;
            top: 50%;
            transform: translate(0,-50%);
          }

          .go-to {
            opacity: 0;
            font-weight: normal;
            font-size: 8pt;
            width: 100px;
            float: right;

            @media screen and (max-width : 767px) {
              opacity: 1;
            }
          }

          .name {
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12pt;

            @media screen and (max-width : 767px) {
              font-size: 11pt;
            }
          }

          .address {
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 9pt;
            margin-top: 6px;
            opacity: .6;
            @media screen and (max-width : 767px) {
              font-size: 8pt;
            }
          }

          .distance {
            transform-origin: 100% 100%;
            transition: transform .3s ease;
            font-size: 12pt;
            padding-right:2px;

            small {
              font-size: 8pt;
            }
          }

          .left.area {
            padding-right:90px;
            left: 0px;
            text-align: left;
          }

          .right.area {
            right: 0px;
            text-align: right;
          }
        }
      }
    }

    .map-container {
      width: 49%;
      display: inline-block;
      position:relative;

      @media screen and (max-width : 767px) {
        width: 100%;
        height: 300px;
        position:absolute;
        top: 0; 
        left: 0;
      }

      .marker.active, .marker:hover {
        transform: scale(1.8);
        z-index: 1000;
      }

      .marker {
        transform-origin: 50% 100%;
        transition: transform .2s ease;
        img {
          position:absolute;
          left: 50%;
          top: 50%;
          width: 60px;
          height: 60px;
          transform: translate(-50%, -50%);
        }
      }


      #map {
        border:1px solid black;
        background-color:#eee;
        padding-bottom: 100%;

        @media screen and (max-width : 767px) {
          padding-bottom:0;
          height: 300px;
        }
      }
    }
  }

}

.loading-results {
  position:absolute;
  top: 0px;
  bottom: 0;
  position:absolute;
  left: 0;
  right: 0;
  background-color:rgba(255,255,255,0.7);
  z-index: 10;

  .spinner {
    position:absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    background-image: url(../images/loader.gif);
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    width: 200px;
    height: 200px;
  }
}

.recaptcha.error {
  .g-recaptcha{
    border-radius:6px;
    border:2px solid red;
  }
}

.thanks-container {
  text-align: center;
  padding:20px;
  .thanks {
    padding-bottom:300px;
    font-weight: bold;
  }
}
.error-container {
  color: darkred;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding:20px;
}

.form-group.error {
  input, select, textarea {
    border:1px solid red;
  }
  .field-error {
    display: block !important;
    color:red;
    font-size: 10pt;
    margin-top:2px;
    margin-bottom: 5px;
  }
}